import http from "./axios";
import {Customer} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export class CustomerService {

    public add = async (customer : Customer) => {
        return http.post<Customer>(`${REACT_APP_SERVER_URL}/customers/add`, {
            ...customer
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public get = async (data: any) => {
        return http.post<Customer>(`${REACT_APP_SERVER_URL}/customers/query`,
            {...data}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
                }})
    }

    public delete = async (customerId: string) => {
        return http.delete<Customer>(`${REACT_APP_SERVER_URL}/customers/delete/${customerId}`,  {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public safeDelete = async (customerId: string) => {
        return http.put<Customer>(`${REACT_APP_SERVER_URL}/customers/update/${customerId}`, {
            active: false
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public update = async (customer: Customer, customerId: string) => {
        return http.put<Customer>(`${REACT_APP_SERVER_URL}/customers/update/${customerId}`, {
            ...customer
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

}
