import InfiniteScroll from "react-infinite-scroll-component";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Product} from "@models";
import {ProductService} from "@services/ProductService";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import { notify } from "../../utils/notify";

export function Products() {

    const navigate = useNavigate();
    const [products, setProducts] = useState<Product[]>([]);
    const [open, setOpen] = React.useState(false);
    const [kgChecked, setCheckedKg] = useState(false);
    const [pzChecked, setCheckedPz] = useState(false);
    const [flag, setFlag] = useState<boolean>(false)
    const [rate, setRate] = useState(0);
    const productService = new ProductService;
    const [refresh, setRefresh] = useState(false)
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [searchBar, setSearchBar] = React.useState(false);
    const [query, setQuery] = useState<string>("");

    const toggleSearchBar = async () => {
        setSearchBar(!searchBar);
    }

    const handleOpen = () => {
        setCheckedPz(false)
        setCheckedKg(false)
        setRate(0)
        setOpen(true);
        setFlag(false)
    }
    const handleClose = () => {
        setOpen(false);
    }

    function getProducts() {
        productService.get({
            query: {
                name: {
                    $regex : query,
                    $options : 'i'
                },
                active: true
            }
        }).then((resp: any) => {
            const products = resp.data['docs'] as Product[];
            setHasMore(resp.data.hasNextPage);
            setProducts(products);
            setPage(resp.data.nextPage)
        })
        .catch((err: any) => {
            console.error(err);
        });
    }

    function getRate(rate: number) {
        if(rate<-99.9){
            setFlag(true)
        }
        else {
            setRate(rate)
            setFlag(false)
        }
    }


    function updatePrices(){
        productService.updateMany(rate, kgChecked, pzChecked)
            .then((resp: any) => {
                handleClose()
                productService.fixPrices("pzPrice")
                    .then((resp: any)=> {
                        productService.fixPrices("kgPrice")
                            .then((resp: any)=> {
                                notify("I prezzi sono stati aggiornati correttamente", "grey")
                                setRefresh(!refresh)
                            }).catch((err: any) => {
                                console.error(err);
                                notify("Non è stato possibile aggiornare i prezzi", "#850000")
                        });
                    })
                    .catch((err: any) => {
                        console.error(err);
                        notify("Non è stato possibile aggiornare i prezzi", "#850000")
                    });
        })
            .catch((err: any) => {
                console.error(err);
                notify("Non è stato possibile aggiornare i prezzi", "#850000")
            });

    }

    const getNextProducts = () =>  {
        productService.get({query: {active: true}, option: {page: page}})
            .then((resp: any) => {
                setProducts(products.concat(resp.data['docs'] as Product[]));
                setHasMore(resp.data.hasNextPage);
                setPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    useEffect( () => {
        console.log("changingnnn");
        getProducts();
    }, [refresh, query]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "100vh",
                        },
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" className="flex justify-between">
                    <div>
                        <i onClick={handleClose} className=" cursor-pointer	fa-solid fa-xmark fa-3x"></i>
                    </div>
                    <div className="text-5xl pt-2">
                        Aggiorna Prezzi
                    </div>
                    <div className="invisible">03</div>
                </DialogTitle>

                <DialogContent className="mt-4">
                    <div className="flex flex-col items-center">

                            <div className="text-xl mb-6">Seleziona i prezzi da variare</div>
                            <div className="m-auto mb-7">
                                <div className="bg-input rounded-xl mb-5 p-2">
                                    <label className="cursor-pointer flex items-center justify-between">
                                        <input type="checkbox" className="checkbox bg-secondary checkbox-lg mr-2"  id="kgPrice" onChange={() => setCheckedKg(!kgChecked)}/>
                                        <span className="label-text justify-self-start text-2xl">Prezzi al peso</span>
                                    </label>
                                </div>

                                <div className="bg-input rounded-full p-2">
                                    <label className="cursor-pointer flex items-center justify-between">
                                        <input type="checkbox" className="checkbox bg-secondary checkbox-lg mr-2" onChange={() => setCheckedPz(!pzChecked)}/>
                                        <span className="label-text justify-self-start text-2xl">Prezzi al pezzo</span>
                                    </label>
                                </div>
                            </div>


                            <div className="text-xl mb-6">Modifica tutti i prezzi di un valore percentuale</div>

                            {flag ? <div className="text-red-600 mb-5">Non puoi ridurre i prezzi dell'oltre 99%</div>: ""}

                            <div className="mb-7 relative flex items-center flex-row-reverse">
                                <i className="absolute fa-solid fa-percent fa-2xl mr-12 opacity-50"></i>
                                <input
                                    id="rateE" type="number" min={-99}
                                    className="text-xl text-center input-md bg-input rounded-full shadow-10"
                                    onChange={(e) => getRate(+e.target.value)}
                                />

                            </div>

                            <button className="btn btn-lg btn-primary rounded-full m-2" disabled={!(kgChecked || pzChecked) || rate===0  || flag===true} onClick={() => updatePrices()}>
                                Applica
                            </button>
                    </div>
                </DialogContent>
            </Dialog>


            <div className="content">
                <div className="flex justify-between items-center w-full gap-2">
                    <h6 className='title title-margin text-left'>Prodotti</h6>
                    <div className="w-full transition-all">
                        {
                            (searchBar)
                                ?
                                <label className="relative text-gray-600" htmlFor="query" id="expanded-bar">
                                    <span
                                        className=" text-gray-400 absolute inset-y-0 left-0 flex items-center pl-4 focus:outline-none focus:shadow-outline">
                                        <i className="fa fa-search"></i>
                                    </span>
                                    <input autoFocus name="query" id="query"
                                           onBlur={toggleSearchBar}
                                           className="input-field shadow-10 w-full rounded-full pl-10 focus:outline-none"
                                           placeholder="Cerca..." value={query} onChange={e => setQuery(e.target.value)}/>
                                </label>
                                :
                                <button onClick={toggleSearchBar}
                                        className="flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 text-white float-right">
                                    <i className="fa fa-search"></i>
                                </button>
                        }
                    </div>
                    {/*<div className="flex justify">*/}
                        <a onClick={() => handleOpen()} className="btn btn-lg btn-primary rounded-full m-2">
                            <i className="fa-solid fa-percent"></i>
                        </a>
                        <a className="btn btn-lg btn-primary rounded-full" onClick={() => navigate("/products/manage")}>
                            <i className="fa-solid fa-circle-plus mr-2"></i> Nuovo
                        </a>
                    {/*</div>*/}
                </div>

                <div className="pb-10 h-scroll-2 rounded-xl mt-10" id="scrollableDiv">
                <InfiniteScroll
                    next={getNextProducts}
                    hasMore={hasMore}
                    loader = {
                        [
                            ...Array(10),
                        ].map((value: undefined, index: number) => (
                            <div className=" rounded-xl animate-pulse">
                                <div className="mt-1 px-10 py-5 std-radius flex justify-between items-center bg-input">
                                    {/*Order name*/}
                                    <div className="h-14 w-1/2 bg-gray-200 rounded-xl">
                                    </div>
                                    {/*Buttons*/}
                                    <div className=" flex justify-between items-center space-x-3">
                                        <div className="flex-col items-center">
                                            <div className="h-6 w-36 bg-gray-200 rounded-full mb-1">
                                            </div>
                                            <div className="h-7 w-36 bg-gray-200 rounded-full">
                                            </div>
                                        </div>
                                        <div className="flex-col items-center">
                                            <div className="h-6 w-36 bg-gray-200 rounded-full mb-1">
                                            </div>
                                            <div className="h-7 w-36 bg-gray-200 rounded-full">
                                            </div>
                                        </div>

                                        <button className="space-x-3 h-10 w-50 btn btn-lg border-none flex items-center justify-around rounded-full bg-gray-200">
                                            <i className="fa-solid fa-pencil "></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    dataLength={products.length}
                    scrollableTarget="scrollableDiv"
                    endMessage={<div className="mt-2">Non ci sono altri prodotti</div>}
                >
                        {products?.map((product) => {
                            return (
                                <div className="mt-1 px-10 py-5 std-radius flex justify-between items-center bg-input">
                                {/*Order name*/}
                                <div className="text-2xl font-bold text-left max-w-[70%]">
                                    {product.name}
                                </div>
                                {/*Buttons*/}
                                <div className=" flex justify-between items-center space-x-3">
                                    {/*Importo*/}
                                    <div className="flex-col items-center">
                                        <div className="font-bold">
                                            1 Pz
                                        </div>
                                        <div className="text-primary ">
                                            <span className="text-4xl">€ {product.pzPrice ? product.pzPrice.toFixed(2) : "nd"}</span>{/*<span className="text-2xl">.44</span>*/}
                                        </div>
                                    </div>
                                    {/*Concluso toggle switch*/}
                                    <div className="flex-col space-x-3 items-center">
                                        <div className="font-bold">
                                            1 Kg
                                        </div>
                                        <div className="text-primary">
                                            <span className="text-4xl">€ {product.kgPrice ? product.kgPrice.toFixed(2) : "nd"}</span>{/*<span className="text-2xl">.49</span>*/}
                                        </div>
                                    </div>
                                    {/*Info button*/}
                                    <button className="space-x-3 h-10 w-50 btn btn-lg border-none flex items-center justify-around rounded-full btn-primary" onClick={() => navigate(`/products/manage?id=${product._id}`)} >
                                        <i className="fa-solid fa-pencil "></i>
                                    </button>

                                </div>
                            </div>
                            )
                        })}
                </InfiniteScroll>
                </div>
            </div>
        </div>
    );
}
