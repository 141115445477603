import http from "./axios";
import {FileName} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export class FileService {

    public add = async (form : FormData) => {
        return http.post<FileName>(`${REACT_APP_SERVER_URL}/files/add`, form, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public get = async (data: any) => {
        return http.post<FileName>(`${REACT_APP_SERVER_URL}/files/query`,
            {...data}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
                }})
    }

    public getImage = async (imageName : string) => {
        return http.get<Blob>(`${REACT_APP_SERVER_URL}/public/images/${imageName}`,  {
            headers: {
                "Response-Type" : "blob",
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public delete = async (imageId: string) => {
        return http.delete<File>(`${REACT_APP_SERVER_URL}/files/delete/${imageId}`,  {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

}
