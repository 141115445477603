import React, {useEffect, useState} from "react";
import {Customer} from "@models";
import {CustomerService} from "@services/CustomerService";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from "react-router-dom";
import { notify } from "../../utils/notify";

export function Customers() {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [open, setOpen] = React.useState(false);
    const [deletingId, setDeletingId] = useState<string>();
    const customerService = new CustomerService;
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const handleOpen = (customerId: string) => {
        setOpen(true);
        setDeletingId(customerId);
    };
    const handleClose = () => {
        setOpen(false);
        setDeletingId(null);
    };
    const handleDelete = () => {
        //console.log("deletingId");
        customerService.safeDelete(deletingId).then(()=>{
            notify("Cliente eliminato correttamente", "grey")
        }).catch((err: any) => {
            console.error(err)
            notify("Non è stato possibile eliminare il cliente", "#850000")
        });
        getCustomers();
        setOpen(false);
    }

     function getCustomers() {
        customerService.get({ query : {}})
            .then((resp: any) => {
                const customers = resp.data['docs'] as Customer[];
                setCustomers(customers);
                setHasMore(resp.data.hasNextPage);
                setPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    const getNextCustomers = () =>  {
        customerService.get({query: {active: true}, option: {page: page}})
            .then((resp: any) => {
                setCustomers(customers.concat(resp.data['docs'] as Customer[]));
                setHasMore(resp.data.hasNextPage);
                setPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    useEffect( () => {
        getCustomers();
    }, []);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                style={{
                    borderRadius: 2
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <span className="text-xl font-bold">Sicuro di voler eliminare?</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà il cliente dalla lista.
                        I suoi precedenti ordini non verranno eliminati.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className="flex items-center justify-around btn rounded-full btn-secondary">
                        Annulla
                    </button>
                    <button onClick={handleDelete} className="flex items-center justify-around btn rounded-full btn-warning border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>
            <div className="content">
                <div className="flex justify-between items-center">
                    <h6 className='title title-margin text-left'>Clienti</h6>
                    <a onClick={() => navigate("/customers/manage")}
                       className=" flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 mr-10 text-white">
                        <i className="fa-solid fa-user-plus"></i>Nuovo cliente
                    </a>
                </div>
                <div className="mt-10 pb-10">

                    <div className="pb-10 h-scroll-2 rounded-xl mt-10" id="scrollableDiv">
                        <InfiniteScroll
                            next={getNextCustomers}
                            hasMore={hasMore}
                            loader={
                                [
                                    ...Array(10),
                                ].map((value: undefined, index: number) => (
                                    <div className=" rounded-xl animate-pulse">
                                        <div className="mt-1 px-10 py-5 std-radius flex justify-between items-center bg-input">
                                            <div className="h-14 w-1/2 bg-gray-200 rounded-xl">
                                            </div>
                                            <div className=" flex justify-between items-center space-x-3">
                                                <button className="space-x-3 h-10 w-50 btn btn-lg border-none flex items-center justify-around rounded-full bg-gray-200">
                                                    <i className="fa-solid fa-user-pen "></i>
                                                </button>

                                                <button className="space-x-3 h-10 w-50 btn btn-lg border-none flex items-center justify-around rounded-full bg-gray-200">
                                                    <i className="fa-solid fa-trash-can "></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            dataLength={10}
                            scrollableTarget="scrollableDiv"
                            className="mt-10 rounded-xl"
                            endMessage={<div className="mt-2">Non ci sono altri clienti</div>}
                        >
                            {customers?.map((customer) => {
                                return (
                                    <div className="mt-1 px-10 py-5 std-radius flex justify-between items-center bg-input">
                                        <div className="text-2xl font-bold">{customer.name}</div>
                                        <div className="mt-1 std-radius flex justify-between items-center gap-2">
                                            <a onClick={() => navigate(`/customers/manage?id=${customer._id}`)} className="h-10 w-30 btn btn-lg border-none flex items-center justify-around rounded-full btn-secondary">
                                                <div className="m-1 text-xl">
                                                    <i className="fa-solid fa-user-pen"></i>
                                                </div>
                                            </a>
                                            <button onClick={() => handleOpen(customer._id)} className="h-10 w-30 btn btn-lg border-none flex items-center justify-around rounded-full bg-warning hover:bg-warning text-white">
                                                <div className="m-1 text-xl">
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                );
                            })
                            }
                    </InfiniteScroll>
                    </div>
                </div>
            </div>
        </div>
    );
}
