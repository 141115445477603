import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {OrderService} from "@services/OrderService";
import {OrderProduct} from "../models/OrderProduct";
import {Order, Product} from "@models";

export function Dashboard() {
    const navigate = useNavigate();

    useEffect( () => {
        let monthAgo = new Date();
        monthAgo.setMonth(monthAgo.getMonth() - 1);
        getUndoOrders()
        getMonthlyOrders()
        getMonthlyPayment()
    }, []);

    const [undoOrders, setUOrders] = useState<number>(null);
    const [monthlyOrders, setMOrders] = useState<number>(null);
    const [monthlyPayment, setMPayment] = useState<number>(null);

    const currentDate = new Date();
    const firstDay = new Date(new Date().getFullYear(), currentDate.getMonth(),0).toISOString();
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 1).toISOString();
    const orderService = new OrderService;

    function getUndoOrders() {
        orderService.get({ query: {
                user: localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_USER),
                isCompleted : false
            }
        })
            .then((resp: any) => {
                console.log(resp)
                setUOrders(resp.data.docs.length)
            })
            .catch((err: any) => {
            console.error(err);
        });
    }

    function getMonthlyOrders(){
        orderService.get({ query: {
            user: localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_USER),
            orderDate : {$gt: firstDay, $lte : lastDay},
        }
        })
            .then((resp: any) => {
                //console.log(resp)
                setMOrders(resp.data.docs.length)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    function getMonthlyPayment(){
        orderService.get({ query: {
                user: localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_USER),
                orderDate : {$gt: firstDay, $lte : lastDay},
                isPaid : true
            }
        })
            .then((resp: any) => {
               setMPayment(resp.data.docs.reduce((a : number,b : Order) => a + b.products.reduce((acc, curr) => acc + curr.totalPrice,0),0)
               )
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    return (
        <div>
            <div className="content">
                <h6 className='title title-margin text-left' >Operazioni rapide</h6>
                    <div className="flex items-start space-x-4 mt-5 overflow-x-auto">
                        <a onClick={() => navigate("/orders/manage")} className="flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2">
                            <i className="fa-solid fa-calendar-plus"></i>Nuovo ordine
                        </a>
                        <a onClick={() => navigate("/customers/manage")} className="flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2">
                            <i className="fa-solid fa-user-plus"></i>Aggiungi cliente
                        </a>
                        <a onClick={() => navigate("/orders")} className="flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2">
                            <i className="fa-solid fa-list"></i>Ordini
                        </a>
                    </div>
                <h6 className='title title-margin text-left mt-10'>Dashboard</h6>
                <div className='flex items-center gap-10 mt-5'>
                    <div className="w-80 rounded-xl bg-input">
                        <div className="select-none duration-500 ease-in-out rounded-xl h-20 flex flex-col items-center justify-center min-w-min max-w-[100%] bg-secondary" style={{width: ""}}>
                            <div className="mx-1 text-2xl">
                                €{  (monthlyPayment == null ) ?  <i className="fa fa-spinner fa-spin ml-2"></i> : monthlyPayment?.toFixed(2)}
                            </div>
                            <div className="mx-1 text-xs">
                                pagati
                            </div>
                        </div>
                    </div>
                    <div className="text-2xl">Questo mese</div>
                </div>
                <div className='flex items-center gap-10 mt-5'>
                        <div className="select-none rounded-xl h-20 w-20 flex items-center justify-around bg-secondary">
                            <div className="m-1 text-2xl">
                                {(monthlyOrders == null) ? <i className="fa fa-spinner fa-spin ml-2"></i> : monthlyOrders}
                            </div>
                        </div>
                    <div className="text-2xl">
                        Nuov{monthlyOrders === 1 ? "o" : "i"} lavor{monthlyOrders === 1 ? "o" : "i"} questo mese
                    </div>
                </div>
                <div className='flex items-center gap-10 mt-5'>
                    <div className="select-none rounded-xl h-20 w-20 flex items-center justify-around bg-warning text-secondary">
                        <div className="m-1 text-2xl">
                            {(undoOrders == null) ? <i className="fa fa-spinner fa-spin ml-2"></i> : undoOrders}
                        </div>
                    </div>
                    <div className="text-2xl">
                        Operazion{undoOrders === 1 ? "e" : "i"} non conclus{undoOrders === 1 ? "a" : "e"}
                    </div>
                </div>
            </div>
        </div>
    );
}
