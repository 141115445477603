import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Product} from "@models";
import {useForm} from "react-hook-form";
import {ProductService} from "@services/ProductService";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import { notify } from "@utils/notify";

export function ManageProduct() {
    const navigate = useNavigate();
    const [product, setProduct] = useState<Product>(null);
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const [searchParams, setSearchParams] = useSearchParams();

    const productId =  searchParams.get("id");
    const productService = new ProductService;

    const handleSubmitCallback = (data: any) => {

        const product: Product = {...data};
        setIsSaving(true)

        if (!!productId) {
            productService.update(product, productId).then(() => {
                notify("Prodotto aggiornato correttamente", "grey");
                navigate('/products');
            }).catch((err: any) => {
                console.error(err);
                setIsSaving(false)
                notify("Non è stato possibile modificare il prodotto", "#850000")
            })
        }
        else {
            productService.add(product).then(() => {
                notify("Nuovo prodotto creato correttamente", "grey");
                navigate('/products');
            }).catch((err: any) => {
                console.error(err);
                setIsSaving(false)
                notify("Non è stato possibile creare il prodotto", "#850000")
            });
        }
    };

    useEffect( () => {
        if(!!productId){
            const query = { _id: productId.toString()};
            productService.get(query)
                .then((resp: any) => {
                    //console.log(resp);
                    setProduct(resp.data.docs[0])}
                ).catch((err: any) => {
                console.error(err);
            });
        }
    }, [setProduct]);

    useEffect( () => {
        reset({ ...product });
    }, [product])

    const [open, setOpen] = React.useState(false);
    const [deletingId, setDeletingId] = useState<string>();

    const handleOpen = (productId: string) => {
        setOpen(true);
        setDeletingId(productId);
    };
    const handleClose = () => {
        setOpen(false);
        setDeletingId(null);
    };
    const handleDelete = () => {
        productService.safeDelete(deletingId).then(() =>{
            notify("Prodotto eliminato correttamente", "grey")
        }).catch((err: any) =>{
            console.error(err)
            notify("Non è stato possibile eliminare il prodotto", "#850000")
        });
        setOpen(false);
        navigate("/products");
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                style={{
                    borderRadius: 2
                }}>
                <DialogTitle id="alert-dialog-title">
                    <span className="text-xl font-bold">Sicuro di voler eliminare?</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà il prodotto dalla lista.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className="flex items-center justify-around btn rounded-full btn-secondary">
                        Annulla
                    </button>
                    <button onClick={handleDelete} className="flex items-center justify-around btn rounded-full btn-warning border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>
            <div className="content mx-10">
                <form onSubmit={handleSubmit(handleSubmitCallback)}>
                    <div className="flex items-center justify-between">
                        <label className="title text-gray-700 mr-3 leading-tight">
                            {productId ?  ("Modifica Prodotto") : ("Nuovo Prodotto")}
                        </label>
                        <div className="flex">
                            {productId ?  <button type="button" onClick={() => handleOpen(productId)}
                                                  className="btn-secondary flex items-center btn btn-lg btn-primary rounded-full gap-2 mr-5" disabled={isSaving}
                            >
                                <i className="fa-solid fa-trash-can"></i>
                            </button> : null}
                            <button type="submit" className="btn-primary flex items-center btn btn-lg btn-primary rounded-full gap-2 mr-10" disabled={isSaving}
                            >
                                <i className="fa-solid fa-user-check"></i> Salva
                            </button>
                        </div>
                    </div>

                    <div className="mt-5 flex justify-between gap-10">
                        <div className="w-1/2">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4" htmlFor="productName">
                                    <span className="label-text">Nome Prodotto</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.name && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="name" type="text" maxLength={100}
                                className="input input-md bg-input w-full rounded-full input-field shadow-10"
                                {...register("name", { required: true, maxLength: 100 })}
                            />
                        </div>
                        <div className="w-1/2">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4" htmlFor="productCode">
                                    <span className="label-text">Codice Prodotto</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.code && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="code" type="text" maxLength={100}
                                className="input input-md bg-input w-full rounded-full input-field shadow-10"
                                {...register("code", { required: true, maxLength: 100 })}
                            />
                        </div>
                    </div>

                    <div className="mt-5 flex justify-between gap-10">
                        <div className="w-1/2">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4" htmlFor="pzPrice">
                                    <span className="label-text">Prezzo al pezzo (1 Pz)</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.pzPrice && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="pzPrice" step="any" type="number" maxLength={100} min={0.01}
                                className="input input-md bg-input w-full rounded-full input-field shadow-10"
                                {...register("pzPrice", { required: true, maxLength: 100 })}
                            />
                        </div>
                        <div className="w-1/2">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4" htmlFor="kgPrice">
                                    <span className="label-text">Prezzo al kilo (1 Kg)</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.kgPrice && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="kgPrice" step="any" type="number" maxLength={100} min={0.01}
                                className="input input-md bg-input w-full rounded-full input-field shadow-10"
                                {...register("kgPrice", { required: true, maxLength: 100 })}
                            />
                        </div>
                    </div>

                    <div className="mt-5 flex justify-between gap-10">
                        <div className="w-1/2">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4" htmlFor="pzPerKg">
                                    <span className="label-text">Pezzi per Kg</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.pzPerKg && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="pzPerKg" step="any" type="number" maxLength={100} min={1}
                                className="input input-md bg-input w-full rounded-full input-field shadow-10"
                                {...register("pzPerKg", { required: true, maxLength: 100 })}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
