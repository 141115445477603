import React, {useEffect, useState}  from "react";
import {useLocation, useNavigate} from "react-router-dom";

export default function Sidebar({user}: any) {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState<boolean>(true)

    const [touchStart, setTouchStart] = useState<number>(null)
    const [touchEnd, setTouchEnd] = useState<number>(null)
    const [shop, setShop] = useState<string>("");

    useEffect(() => {
        setShop(user?.shop);
    }, [])



    const minSwipeDistance = 50

    const onTouchStart = (e: any) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e: any) => {
        setTouchEnd(e.targetTouches[0].clientX)
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe: boolean = distance > minSwipeDistance
        const isRightSwipe: boolean = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) {
            isLeftSwipe ? setIsOpen(false) : setIsOpen(true)
        }
    }

    const toggle = () => setIsOpen(!isOpen);

    const logout = () => {
        localStorage.clear();
        navigate('/auth/login');
    };

    let path = useLocation().pathname;

    return (


        <div className="h-screen" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <div
                className={isOpen ? "flex flex-col justify-between w-96 static sidebarM  flex-col justify-between" : "flex flex-col justify-between w-28 tatic sidebarM h-full h-screen flex-col justify-between"}
                style={{height: "100vh"}}>
                <div>
                    <label htmlFor="my-drawer" className="drawer-overlay flex mt-4">
                        <img className="logo" style={{margin: 'auto', height: '7em'}}
                             src={user?.shop[0] === "MUSEO"  ? isOpen ? "/mucci_museo_logo.png" : "/mucci-logo-min.png" : isOpen ? "/mucci_logo.png" : "/mucci-logo-min.png"} alt="Mucci Logo"/>
                    </label>
                    <div className="flex flex-col mt-5 text-base-content ">
                        <div
                            className={`${path === "/dashboard" ? "bg-white" : "bg-white/50"} rounded-2xl m-2 p-4 flex sidebar-menu gap-3 cursor-pointer`}
                            onClick={() => navigate("/dashboard")}>
                            <div className="w-20"><i className="fa-solid fa-house fa-xl "/></div>
                            {isOpen ? <div>Dashboard</div> : null}
                        </div>
                        <div
                            className={`${(path === "/orders/manage") || (path === "/orders") ? "bg-white" : "bg-white/50"} rounded-2xl m-2 p-4 flex sidebar-menu gap-3 cursor-pointer`}
                            onClick={() => navigate("/orders")}>
                            <div className="w-20"><i className="fa-solid fa-list fa-xl "/></div>
                            {isOpen ? <div>Ordini</div> : null}
                        </div>
                        <div
                            className={`${(path === "/customers/manage") || (path === "/customers") ? "bg-white" : "bg-white/50"} rounded-2xl m-2 p-4 flex sidebar-menu gap-3 cursor-pointer`}
                            onClick={() => navigate("/customers")}>
                            <div className="w-20"><i className="fa-solid fa-users fa-xl "/></div>
                            {isOpen ? <div>Clienti</div> : null}
                        </div>
                        <div
                            className={`${(path === "/products/manage") || (path === "/products") ? "bg-white" : "bg-white/50"} rounded-2xl m-2 p-4 flex sidebar-menu gap-3 cursor-pointer`}
                            onClick={() => navigate("/products")}>
                            <div className="w-20">
                                <img className="sidebar-icon p-0" src="/tenerelli.svg"/>
                            </div>
                            {isOpen ? <div>Prodotti</div> : null}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center">
                    <div className="flex m-2">
                        {isOpen ? <div
                            className="select-none bg-white rounded-2xl m-2 px-4 py-3 relative text-center w-64 uppercase tracking-wider flex items-center justify-around">
                            <div>
                                <i className="fa-solid fa-xl fa-user"/>
                            </div>
                            <div>
                                {user?.username || "Caricamento..."}
                            </div>
                        </div> : null}
                        <div className="bg-white sidebar-bottom-btn w-24 flex items-center justify-center h-16 m-2"
                             onClick={() => logout()}><i className="fa-solid fa-xl fa-arrow-right-from-bracket"/></div>
                    </div>
                    <div className="flex items-center cursor-pointer gap-2 underline mb-1" onClick={toggle}>
                        <div><i className={`fa-solid fa-solid ${isOpen ? "fa-less-than" : "fa-greater-than"}`}></i>
                        </div>
                        {isOpen ? <div>nascondi</div> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
