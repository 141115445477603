import http from "./axios";
import {Order} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export class OrderService {

    public add = async (order : Order) => {
        return http.post<Order>(`${REACT_APP_SERVER_URL}/orders/add`, {
            ...order
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public get = async (data: any) => {
        return http.post<Order>(`${REACT_APP_SERVER_URL}/orders/query`,
            {...data}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
                }})
    }

    public getAll = async () => {
        return http.get<Order[]>(`${REACT_APP_SERVER_URL}/orders/all`, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        })
    }

    public delete = async (orderId: string) => {
        return http.delete<Order>(`${REACT_APP_SERVER_URL}/orders/delete/${orderId}`,  {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public safeDelete = async (orderId: string) => {
        return http.put<Order>(`${REACT_APP_SERVER_URL}/orders/update/${orderId}`, {
            active: false
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public update = async (order: Order | object, orderId: string) => {
        return http.put<Order>(`${REACT_APP_SERVER_URL}/orders/update/${orderId}`, {
            ...order
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public updatePrice = async (changeRate : number) => {
        return http.put(`${REACT_APP_SERVER_URL}/orders/updatePrice/${changeRate}`, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        })
    }

}
