import http from "./axios";
import {Product} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export class ProductService {

    public add = async (product : Product) => {
        return http.post<Product>(`${REACT_APP_SERVER_URL}/products/add`, {
            ...product
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public get = async (data: any) => {
        return http.post<Product>(`${REACT_APP_SERVER_URL}/products/query`,
            {...data}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
                }})
    }

    public getAll = async () => {
        return http.get<Product[]>(`${REACT_APP_SERVER_URL}/products/all`, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        })
    }

    public delete = async (productId: string) => {
        return http.delete<Product>(`${REACT_APP_SERVER_URL}/products/delete/${productId}`,  {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public safeDelete = async (productId: string) => {
        return http.put<Product>(`${REACT_APP_SERVER_URL}/products/update/${productId}`, {
            active: false
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public update = async (product: Product, productId: string) => {
        return http.put<Product>(`${REACT_APP_SERVER_URL}/products/update/${productId}`, {
            ...product
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public fixPrices = async (price : string) => {
        return http.put<Product>(`${REACT_APP_SERVER_URL}/products/udpate/price`, {
            query: {
                filter: { [price]: {$lte: 0.01}},
                update: {$set: { [price]: 0.01}}
            },
            option: {},
            active: false
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

    public updateMany = async (rate: number, kgPrice: boolean, pzPrice: boolean) => {
        const kgPriceR: number= kgPrice ? rate/100 + 1 : 1;
        const pzPriceR: number= pzPrice ? rate/100 + 1 : 1;
        return http.put<Product>(`${REACT_APP_SERVER_URL}/products/udpate/price`, {
            query: {
                update: {$mul : {kgPrice: kgPriceR, pzPrice: pzPriceR}}
            },
            option: {},
            active: false
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }})
    }

}
