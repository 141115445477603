import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Customer} from "@models";
import {CustomerService} from "@services/CustomerService";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import { notify } from "../../utils/notify";

export function ManageCustomer() {
    const navigate = useNavigate();
    const [customer, setCustomer] = useState<Customer>(null);
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const customerService = new CustomerService;

    const [searchParams, setSearchParams] = useSearchParams();

    const customerId = searchParams.get("id");

    const handleSubmitCallback = (data: any) => {

        const customer: Customer = {...data};
        setIsSaving(true)

        if (!!customerId) {
            customerService.update(customer, customerId).then((resp: any) => {
                notify("Cliente aggiornato correttamente", "grey")
                navigate('/customers');
            }).catch((err: any) => {
                console.error(err)
                setIsSaving(false)
                notify("Non è stato possibile creare il cliente", "#850000")
            });
        }
        else {
            customerService.add(customer).then((resp: any) => {
                notify("Cliente creato correttamente", "grey")
                navigate('/customers');
            }).catch((err: any) => {
                console.error(err)
                setIsSaving(false)
                notify("Non è stato possibile creare il cliente", "#850000")
            });

        }
    };

    useEffect( () => {
        if(!!customerId){
            const query = {query : {_id: customerId.toString()}};
            customerService.get(query)
                .then((resp: any) =>
                    setCustomer(resp.data.docs[0])
                ).catch((err: any) => {
                        console.error(err);
                });
        };
    }, [setCustomer]);

    useEffect( () => {
        reset({ ...customer });
    }, [customer])

    const [open, setOpen] = React.useState(false);
    const [deletingId, setDeletingId] = useState<string>();
    const handleOpen = (customerId: string) => {
        setOpen(true);
        setDeletingId(customerId);
    };
    const handleClose = () => {
        setOpen(false);
        setDeletingId(null);
    };
    const handleDelete = () => {
        customerService.safeDelete(deletingId).then(()=>{
            notify("Cliente eliminato correttamente", "grey")
        }).catch((err: any) => {
            console.error(err)
            notify("Non è stato possibile eliminare il cliente", "#850000")
        });
        setOpen(false);
        navigate("/customers");
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                style={{
                    borderRadius: 2
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <span className="text-xl font-bold">Sicuro di voler eliminare?</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà il cliente dalla lista.
                        I suoi precedenti ordini non verranno eliminati.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className="flex items-center justify-around btn rounded-full btn-secondary">
                        Annulla
                    </button>
                    <button onClick={handleDelete} className="flex items-center justify-around btn rounded-full btn-warning border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>

            <div className="content mx-10">
                <form onSubmit={handleSubmit(handleSubmitCallback)}>
                    <div className="flex items-center justify-between">
                        <label className="title text-gray-700 mr-3 leading-tight">
                           {customerId ?  ("Modifica Cliente") : ("Nuovo Cliente")}
                        </label>
                        <div className="flex">
                            {customerId ?  <button type="button" onClick={() => handleOpen(customerId)}
                                     className="btn-secondary flex items-center btn btn-lg btn-primary rounded-full gap-2 mr-5" disabled={isSaving}
                            >
                                <i className="fa-solid fa-trash-can"></i>
                            </button> : null}
                            <button type="submit" className="btn-primary flex items-center btn btn-lg rounded-full gap-2 mr-10" disabled={isSaving}
                            >
                                <i className="fa-solid fa-user-check"></i> Salva
                            </button>
                        </div>
                    </div>

                    <div className="mt-5 flex justify-between gap-10">
                        <div className="w-1/2">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4" htmlFor="name">
                                    <span className="label-text">Nominativo</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.name && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="name" type="text" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10 bg-input"
                                {...register("name", { required: true, maxLength: 100 })}/>
                        </div>
                        <div className="w-1/2">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4" htmlFor="origin">
                                    <span className="label-text">Provenienza</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.shippingAddress && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="origin" type="text" maxLength={100}
                                   className="input input-md w-full rounded-full input-field shadow-10 bg-input"
                                   {...register("origin", {required: false , maxLength: 100 })}/>
                        </div>
                    </div>


                    <div className="mt-5 flex justify-between gap-10">
                        <div className="w-1/2">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4" htmlFor="email">
                                    <span className="label-text">Email</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.email && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="email" type="email" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10 bg-input"
                                {...register("email", {required: true, maxLength: 100 })}/>
                        </div>
                        <div className="w-1/2">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4" htmlFor="telephone">
                                    <span className="label-text">Telefono</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.telephone && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="telephone" type="text" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10 bg-input"
                                {...register("telephone", { required: true, maxLength: 100 })}/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
