import InfiniteScroll from "react-infinite-scroll-component";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Customer, Order} from "@models";
import {OrderService} from "@services/OrderService";


export function Orders() {

    const navigate = useNavigate();
    const [orders, setOrders] = useState<Order[]>([]);
    const [pages, setPages] = useState<number>();
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [sort, setSort] = useState<number>(1);
    const [showCompleted, setShowCompleted] = useState<string>("");
    const [showPaid, setShowPaid] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);
    const orderService = new OrderService;
    const [page, setPage] = useState<number>(1)

    function getOrders() {
        orderService.get({
            query: {
                user: localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_USER),
                isCompleted : {$in : showCompleted === "" ? [true,false] : [showCompleted ==="true"]},
                isPaid : {$in : showPaid === "" ? [true,false] : [showPaid ==="true"]}},
            option: {
                sort: {orderDate : sort},
                populate: {path: "customer", model: "Customer", select: "name"}}})
            .then((resp: any) => {
                //console.log(resp)
                const orders = resp.data.docs as Order[];
                setOrders(orders);
                setHasMore(resp.data.hasNextPage);
                setPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    const getNextOrders = () => {
        orderService.get({
            query: {
                user: localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_USER),
                active: true,
                isCompleted: {$in: showCompleted === "" ? [true, false] : [showCompleted === "true"],
                isPaid : {$in : showPaid === "" ? [true,false] : [showPaid ==="true"]}}
            },
            option: {
                page: page,
                sort: {orderDate: sort},
                populate: {path: "customer", model: "Customer", select: "name"}
            }
        })
            .then((resp: any) => {
                setOrders(orders.concat(resp.data.docs as Order[]));
                setHasMore(resp.data.hasNextPage);
                setPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    const handleIsCompleted = (event: any) => {
        const isCompleted = event.target.checked;
        const idOrder = event.target.value;

        orderService.update({isCompleted : isCompleted}, idOrder).then(() => setRefresh(!refresh));
    }

    const handleIsPaid = (event: any) => {
        const isPaid = event.target.checked;
        const idOrder = event.target.value;
        orderService.update({isPaid: isPaid}, idOrder).then(() => setRefresh(!refresh));
    }

    useEffect( () => {
        getOrders();
    }, [setOrders, sort, showCompleted, showPaid, refresh]);

    return (
        <div className="content">
            <div className="flex justify-between items-center">
                <h6 className='title pl-10 text-left'>Ordini</h6>
                <a className=" flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 mr-9 text-white" onClick={() => navigate("/orders/manage")}>
                    <i className="fa-solid fa-circle-plus"></i>Nuovo ordine
                </a> 
            </div>

            <div className="flex justify-between items-center mt-2">
                <form>
                    <label className="font-bold ml-10 pr-4">Ordine:</label>
                    <select className="form-select rounded-xl bg-input" onChange={(e: any) => setSort(e.target.value)}>
                        <option value={1} selected>Crescente</option>
                        <option value={-1}>Decrescente</option>
                    </select>

                    <label className="font-bold pl-4 pr-4">Pagati:</label>
                    <select className="form-select rounded-xl bg-input" onChange={(e: any) => setShowPaid(e.target.value)}>
                        <option value="" selected>Tutte</option>
                        <option value="true"  >Pagate</option>
                        <option value="false" >Non Pagate</option>
                    </select>

                    <label className="font-bold pl-4 pr-4">Completati:</label>
                    <select className="form-select rounded-xl bg-input" onChange={(e: any) => setShowCompleted(e.target.value)}>
                        <option value="" selected>Tutte</option>
                        <option value="true"  >Completate</option>
                        <option value="false" >Non Completate</option>
                    </select>
                </form>
            </div>
            <div className="pb-10 h-scroll-2 rounded-xl mt-10" id="scrollableDiv">
                <InfiniteScroll
                    next={getNextOrders}
                    hasMore={hasMore}
                    loader={
                        [
                            ...Array(10),
                        ].map((value: undefined, index: number) => (
                            <div className=" rounded-xl animate-pulse">
                                <div className="mt-1 px-10 py-5 std-radius flex justify-between items-center bg-input">

                                    <div className="h-14 w-1/2 flex flex-col items-start">
                                        <div className="h-9 w-full bg-gray-200 mb-1 rounded-full"></div>
                                        <div className="h-7 w-24 bg-gray-200 rounded-full"></div>
                                    </div>

                                    <div className=" flex justify-between items-center space-x-3">
                                        <div className="flex-col items-center">
                                            <div className="h-6 w-32 bg-gray-200 rounded-full mb-1">
                                            </div>
                                            <div className="h-7 w-32 bg-gray-200 rounded-full">
                                            </div>
                                        </div>
                                        <div className="flex-col items-center">
                                            <div className="h-6 w-28 bg-gray-200 rounded-full mb-1">
                                            </div>
                                            <div className="h-7 w-28 bg-gray-200 rounded-full">
                                            </div>
                                        </div>

                                        <div className="flex-col items-center">
                                            <div className="h-6 w-28 bg-gray-200 rounded-full mb-1">
                                            </div>
                                            <div className="h-7 w-28 bg-gray-200 rounded-full">
                                            </div>
                                        </div>


                                        <button className="h-10 w-50 btn btn-lg border-none flex items-center justify-around rounded-full btn-warning" disabled={true} >
                                            <i className="fa-solid fa-circle-info fa-2x"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    dataLength={orders.length}
                    scrollableTarget="scrollableDiv"
                    endMessage={<div className="mt-2">Non ci sono altri ordini</div>}
                >
                        {orders?.map((order) => {
                            return (
                                <div className="mt-1 px-10 py-5 std-radius flex justify-between items-center bg-input">
                                    <div className="flex-col">
                                        <div className="text-4xl">
                                            {order?.customer?.name || "Cliente non trovato"}
                                        </div>
                                        <div className="font-medium text-left">
                                            {new Date(order.orderDate).toLocaleDateString("it")}
                                        </div>
                                    </div>
                                    {/*Buttons*/}
                                    <div className=" flex justify-between items-center gap-3">
                                        {/*Importo*/}
                                        <div className="flex-col items-center">
                                            <div className="font-bold">
                                                Importo
                                            </div>
                                            <div className="text-4xl">
                                                {order.products.reduce((acc,curr) => {
                                                    return acc + curr.totalPrice
                                                },0).toFixed(2)}
                                            </div>
                                        </div>
                                        {/*Concluso toggle switch*/}

                                        <div className="flex flex-col justify-center items-center w-28">
                                            <div className="font-bold pb-4" >Pagato</div>
                                            <label  className="inline-flex relative items-center cursor-pointer mb-0">
                                                <input type="checkbox" onClick={handleIsPaid} value={order._id} checked={order.isPaid} className="sr-only peer"/>
                                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-secondary dark:peer-focus:ring-secondary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:bg-primary after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-800 peer-checked:bg-secondary"/>
                                            </label>
                                        </div>

                                        <div className="flex flex-col justify-center items-center w-28">
                                            <div className="font-bold pb-4" >Completato</div>
                                            <label  className="inline-flex relative items-center cursor-pointer mb-0">
                                                <input type="checkbox" onClick={handleIsCompleted} value={order._id} checked={order.isCompleted} className="sr-only peer"/>
                                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-secondary dark:peer-focus:ring-secondary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:bg-primary after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-800 peer-checked:bg-secondary"/>
                                            </label>
                                        </div>

                                        {/*Info button*/}
                                        <button className="h-10 w-50 btn btn-lg border-none flex items-center justify-around rounded-full btn-warning" onClick={() => navigate(`/orders/manage?id=${order._id}`)} >
                                            <i className="fa-solid fa-circle-info fa-2x"></i>
                                        </button>

                                    </div>
                                </div>
                            )
                        })}
                </InfiniteScroll>
            </div>
        </div>
    );
}
