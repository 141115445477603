import React, {useEffect, useState} from "react";
import {Customer, Order, User, Product} from "@models";
import {useForm} from "react-hook-form";
import {useNavigate, useSearchParams} from "react-router-dom";
import {OrderService} from "@services/OrderService";
import {UserService} from "@services/UserService";
import {CustomerService} from "@services/CustomerService";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {ProductService} from "@services/ProductService";
import {OrderProduct} from "../../models/OrderProduct";
import {FileName} from "../../models/FileName";
import { notify } from "@utils/notify";
import { FileService } from "@services/FileService";

export function ManageOrder() {

    const {REACT_APP_SERVER_URL} = process.env;
    const navigate = useNavigate();
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    const [order, setOrder] = useState<Order>(null);
    const [customers, setCustomers] = useState<Customer[]>(null);
    const [products, setProducts] = useState<Product[]>([]);

    const [pages, setPages] = useState<number>();
    const [hasMore, setHasMore] = useState<boolean>(true);
    let page = 1;

    const [searchParams, setSearchParams] = useSearchParams();
    const orderId =  searchParams.get("id");

    const [isSaving, setIsSaving] = useState<boolean>(false)

    /*Toggle form*/
    const [isNewCustomer, setIsNewCustomer] = useState(!(!!orderId));
    const [isToBeShipped, setIsToBeShipped]  = useState(false);
    const [customerInfo, setCustomerInfo] = useState<Customer>(null);
    const [orderProducts, setOrderProducts] = useState<OrderProduct[]>([]);
    const [isAddingFirstProduct, setIsAddingFirstProduct] = useState(true);
    const [openDialogProduct, setOpenDialogProduct] = React.useState(false);
    const [customProductInfo, setCustomProductInfo]  = React.useState<Product>(null);
    const [typePrice, setTypePrice] = React.useState('pzPrice');
    const [productQuantity, setProductQuantity] = React.useState<number>(1);
    const [isFree, setIsFree] = React.useState<boolean>(false);
    const [discount, setDiscount] = React.useState<number>(0);
    const [toggleDiscount, setToggleDiscount]  = React.useState<boolean>(false);
    const [toggleShowAllProducts, setToggleShowAllProducts] = React.useState<boolean>((!!orderId));
    const [imagePreview, setImagePreview] = React.useState<Array<Blob>>([]);
    const [idNewCustomer, setIdNewCustomer] = React.useState<string>('');
    const [imagesId, setImagesId] = React.useState<string[]>([])
    const [openDialogDeleteOrder, setOpenDialogDeleteOrder] = React.useState<boolean>(false);
    const [openDialogDeleteImage, setOpenDialogDeleteImage] = React.useState<boolean>(false);
    const [deleteIdOrder, setDeleteIdOrder] = React.useState<string>();
    const [filteredProcuts, setFilteredProducts] = React.useState<Product[]>(products);
    const [removeImageId, setRemoveImageId] = React.useState<number>();
    const [user, setUser] = useState<User>(null);

    const customerService = new CustomerService;
    const orderService = new OrderService;
    const productService = new ProductService;
    const fileService = new FileService;

    const addToTheList = (event : any) => {

        const product = {
            "_id" : customProductInfo._id,
            "name" : customProductInfo.name,
            "code" : customProductInfo.code,
            "pzPrice" : customProductInfo.pzPrice,
            "kgPrice" : customProductInfo.kgPrice,
            "pzPerKg" : customProductInfo.pzPerKg,
            "quantity" : productQuantity,
            "isFree" : isFree,
            "discount" : discount,
            "typeQuantity" : typePrice,
            "totalPrice" : isFree ? 0 : typePrice === 'kgPrice' ? ((customProductInfo.kgPrice * productQuantity) - discount) : ((customProductInfo.pzPrice * productQuantity) - discount)
        }

        if (product.totalPrice < 0){
            notify("Non è possibile inserire un prodotto con prezzo negativo, è possibile inserire un prodotto gratuito cliccando su 'omaggio'", "#850000")
            return
        }

        orderProducts.push(product);
        setToggleShowAllProducts(true);
        setToggleDiscount(false);
        setDiscount(0);
        setIsFree(false);
        setProductQuantity(1);
    }

    const handleInputImage = (event : any) => {
        const [image] = event.target.files;
        setImagePreview([...imagePreview, image]);
    }

    const handleDiscount = (event : any) => {
        const discount = event.target.value;
        setDiscount(discount)
    }

    const handleToggleDiscount = () => {
        setToggleDiscount(!toggleDiscount)
        setDiscount(0);
        if(isFree)
            setIsFree(false)
    }

    const handleRemoveFromList = (index : number) => {
        setOrderProducts(orderProducts.filter((orderProduct, indexOrderProduct) => index !== indexOrderProduct));
        if(orderProducts.length === 1) {
            setToggleShowAllProducts(false)
            setIsAddingFirstProduct(true);
        }
    }

    const customProduct = (product : Product) => {
        handleClosaDialogProduct();
        setCustomProductInfo(product);
    }
    const changeQuantity = (event:any) => {
        setProductQuantity(event.target.value);
    }

    const handleIsFree = () => {
        setIsFree(!isFree)
    }

    const changeTypePrice = (event : any) => {
        if(event.target.checked)
            setTypePrice('kgPrice')
        else
            setTypePrice('pzPrice')
    }

    const handleOpenDialogProduct = () => {
        setFilteredProducts(products);
        setOpenDialogProduct(true);
    };
    const handleClosaDialogProduct = () => {
        setFilteredProducts(products);
        setOpenDialogProduct(false);
    };

    const handleNewCustomer = (event : any) => {
        event.target.value === "newCustomer" ? (() => {setIsNewCustomer(true); setInputCustomer(event)})() : (() => {setIsNewCustomer(false); setInputCustomer(event)})()
    };

    const setInputCustomer = (event : any) => {
        const customer = customers.find(customer => customer._id === event.target.value);
        setCustomerInfo(customer)
    }

    const handleIsToBeShipped = () => {
        setIsToBeShipped(!isToBeShipped)
    }

    const handleIsAddingFirstProduct = () => {
        setIsAddingFirstProduct(!isAddingFirstProduct);
    }

    const handleCloseDialogDeleteOrder = () => {
        setOpenDialogDeleteOrder(false);
        setDeleteIdOrder(null);
    }

    const handleDeleteOrder = () => {
        orderService.safeDelete(deleteIdOrder).then(()=>{
            notify("Ordine eliminato correttamente", "grey");
            navigate("/orders");
        }).catch((err: any) => {
            notify("Non è stato possibile eliminare l'ordine", "grey");
            console.error(err);
        });
        setOpenDialogDeleteOrder(false);
    }

    const handleCloseDialogDeleteImage = () => {
        setOpenDialogDeleteImage(false);
    }

    const handleOpenDialogDeleteOrder = (orderId: string) => {
        setOpenDialogDeleteOrder(true);
        setDeleteIdOrder(orderId);
    }

    const handleOpenDialogDeleteImage = (indexImage : number ) => {
        setOpenDialogDeleteImage(true);
        setRemoveImageId(indexImage);
    }

    const handleRemoveImageFromList = (indexImage : number ) => {
        const idImage = imagesId[indexImage];
        fileService.delete(idImage).then((r : any) => r.status === 200 ? notify("Allegato eliminato correttamente", "grey") : notify("Errore durante l'eliminazione dell'allegato", "#850000"));
        setImagePreview(imagePreview.filter((image, index) => index != indexImage))
        setOpenDialogDeleteImage(false)
    }

    const handleSubmitCallback = async (data: any) => {

        setIsSaving(true)

        if (orderProducts.length === 0) {
            notify("Inserire almeno un prodotto", "#850000");
            setIsSaving(false)
            return;
        }

        if (data?.shippingDate > data?.eventDate || data?.pickUpDate > data?.eventDate) {
            notify("La data di spedizione / ritiro non può essere successiva a quella dell'evento", "#850000")
            setIsSaving(false)
            return;
        }


        if (isToBeShipped && (data.shippingAddress === null || data.shipper === "")) {
            notify("Completa i dati relativi alla spedizione", "#850000")
            setIsSaving(false)
            return;
        }

        if (isToBeShipped === false){
            delete data.shipper;
            delete data.shippingAddress;
            delete data.shippingPrice;
        }

        delete data.attachments;

        if (isNewCustomer) {
            const newCustomer = {
                "name": data?.name,
                "origin": data?.origin,
                "email": data?.email,
                "telephone": data?.telephone
            } as Customer;
            const idCustomer = await customerService.add(newCustomer).then((res : any) => res.data._id);
            data.customer = idCustomer;
        }
        const imagesId : any = [];

        for (const image of imagePreview){
            const form = new FormData;
            form.append('file', image);
            const id = await fileService.add(form).then((r : any) => r.data._id).catch((err: any)=> {
                console.error(err)
            })
            imagesId.push({"_id" : id});
        }

        data.attachments = await imagesId;

        delete data.name;
        delete data.origin;
        delete data.email;
        delete data.telephone;

        const orderDate = orderId ? data.orderDate : new Date().toISOString();

        const order: Order = {...data, "products": orderProducts, "orderDate": orderDate, user: localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_USER)};

        if (!!orderId) {
            orderService.update(order, orderId).then((resp: any) => {
                notify("Ordine aggiornato correttamente", "grey");
                navigate('/orders');
            }).catch((err: any) => {
                notify("Non è stato possibile aggiornare l'ordine", "#850000")
                setIsSaving(false)
                console.error(err);
            })
        } else {
            orderService.add(order).then((resp: any) => {
                notify("Ordine creato correttamente", "grey");
                navigate('/orders');
            }).catch((err: any) => {
                notify("Non è stato possibile creare l'ordine", "#850000")
                setIsSaving(false)
                console.error(err)
            });
        }

        navigate("/orders");
    };

    const handleSearchBarProducts = (event:any) => {
        const filter = event.target.value;
        if(!!event.target.value){
            setFilteredProducts(products.filter((product) =>
                product.name.includes(filter)               ||
                product.name.toUpperCase().includes(filter) ||
                product.name.toLowerCase().includes(filter) ||
                product.code.includes(filter)               ||
                product.code.toLowerCase().includes(filter) ||
                product.code.toLowerCase().includes(filter)
            ))
        }else{
            setFilteredProducts(products)
        }
    }

    function getCustomers() {
        customerService.get({query : {}})
            .then((resp: any) => {
                const customers = resp.data['docs'] as Customer[];
                setCustomers(customers);
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    function getProducts() {
        productService.get({
            query: {
                active: true
            }
        }).then((resp: any) => {
            const products = resp.data['docs'] as Product[];
            setPages(resp.data.totalPages);
            if(resp.data.totalPages === 1) setHasMore(false);
            setProducts(products);
        })
        .catch((err: any) => {
            console.error(err);
        });
    }

    const getNextProducts = () =>  {
        if (page >= pages) {
            setHasMore(false);
            return;
        }
        productService.get({query: {active: true}, option: {page: ++page}})
            .then((resp: any) => {
                setProducts(products.concat(resp.data['docs'] as Product[]));
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    useEffect( () => {
        if(!!orderId){
            const query = { query : {_id: orderId.toString()}};
            orderService.get(query)
                .then(async (resp: any) => {
                        const order = resp.data.docs[0]
                        if (!!order.eventDate)
                            order.eventDate = order.eventDate.substring(0, 10)
                        if (!!order.pickUpDate)
                            order.pickUpDate = order.pickUpDate.substring(0, 10)
                        if (!!order.shippingDate)
                            order.shippingDate = order.shippingDate.substring(0, 10)
                        await customerService.get({query: {_id: order.customer}}).then((r: any) => setCustomerInfo(r.data.docs[0]))
                        setOrderProducts(order?.products);
                        setIsAddingFirstProduct(false);
                        setIsToBeShipped(order?.isShipped);

                        for (const image of order?.attachments){
                            const imageName = await fileService.get({query: {_id: image._id}}).then((r : any) => r.data.docs[0].name)
                            const fetchedBlob = await fetch(`${REACT_APP_SERVER_URL}/public/images/${imageName}`).then(res => res.blob()).then(blob => blob)
                            imagePreview.push(fetchedBlob)
                            setImagePreview(imagePreview)
                        }

                        return setOrder(order)
                    }
                ).catch((err: any) => {
                console.error(err);
            });
        };
        getCustomers();
        getProducts();
    }, [setOrder]);


    useEffect( () => {
        reset({ ...order });
    }, [order]);

    useEffect(() => {
        UserService.me().then( (resp: any) => {
            setUser(resp.data);
        });
    }, []);

    const handleIsCompletedOrder = (event : any ) => {
        const _idOrder = event.target.value;
    }

    return (
        <div className="content mx-10">
            <form onSubmit={handleSubmit(handleSubmitCallback)} encType="multipart/form-data">
                <div className="flex items-center justify-between">
                    <label className="title text-gray-700 mr-3 leading-tight">
                        {orderId ?  ("Modifica Ordine") : ("Nuovo Ordine")}
                    </label>
                    <div className="flex">
                        {orderId ? <button onClick={ () => handleOpenDialogDeleteOrder(orderId)} type="button" className="btn-primary flex items-center btn btn-lg btn-primary rounded-full gap-2 mr-5" disabled={isSaving}>
                            <i className="fa-solid fa-trash-can"></i>
                        </button> : null}
                        <button type="submit" className="btn-primary flex items-center btn btn-lg btn-primary rounded-full gap-2 mr-10" disabled={isSaving}
                        >
                            <i className="fa-solid fa-check"></i> Salva
                        </button>
                    </div>
                </div>

                <div className="mt-5 flex justify-between gap-10">
                    <div className="w-1/2">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4" htmlFor="operator">
                                <span className="label-text">Operatore</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5">{errors.operator && "Campo obbligatorio"}</span>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex-col h-full">
                            <select {...register("operator", { required: true, maxLength : 100 })} className="form-select  input-md bg-input w-full rounded-full input-field">
                                <option selected={true} disabled={true} value="">Seleziona Utente</option>
                                {(user?.shop[0].toUpperCase() === "MUSEO") ?
                                    <>
                                        <option value="Loredana">Loredana</option>
                                        <option value="Manuela">Manuela</option>
                                        <option value="Dina">Dina</option>
                                        <option value="Angela">Angela</option>
                                        <option value="Graziana">Graziana</option>
                                        <option value="Luciana">Luciana</option>
                                        <option value="Grazia">Grazia</option>
                                        <option value="Valentina">Valentina</option>
                                        <option value="Laura">Laura</option>
                                    </>
                                    :
                                    <>
                                        <option value="Antonella">Antonella</option>
                                        <option value="Carmela">Carmela</option>
                                        <option value="Cinzia">Cinzia</option>
                                        <option value="Anna">Anna</option>
                                        <option value="Loredana">Loredana</option>
                                    </>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="flex items-center justify-between invisible">
                            <label className="label font-bold pl-4">
                                <span className="label-text"></span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex items-center items-start">
                            <label className="inline-flex relative items-center cursor-pointer mt-3">
                                <input onClick={handleIsToBeShipped} type="checkbox" className="sr-only peer" {...register("isShipped", { required: false })}/>
                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-secondary dark:peer-focus:ring-secondary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:bg-primary after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-800 peer-checked:bg-secondary"/>
                            </label>
                            <div className="font-bold ml-3 mt-3" >Da spedire</div>
                        </div>
                    </div>
                </div>

                {/*Optional content for shipping*/}
                {isToBeShipped ?
                    <div className="mt-5 flex  flex-col justify-between">
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold pl-4">
                                    <span className="label-text recurrence" >Indirizzo</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.shippingAddress && "Campo obbligatorio"}</span>
                                <span className="text-error title-margin label-text mt-1 pr-5"></span>
                            </div>
                            <div className="flex-col h-full">
                                <input {...register("shippingAddress", { required: true, maxLength : 100 })} id="shippingAddress"  className="form-select  input-md bg-input w-full rounded-full input-field" placeholder={"Indirizzo di spedizione"}>
                                </input>
                            </div>
                        </div>
                        <div className="mt-5 flex justify-between gap-10">
                            <div className="w-1/2">
                                <div className="flex items-center justify-between">
                                    <label className="label font-bold pl-4">
                                        <span className="label-text recurrence" >Corriere</span>
                                    </label>
                                    <span className="text-error title-margin label-text mt-1 pr-5">{errors.shipper && "Campo obbligatorio"}</span>
                                    <span className="text-error title-margin label-text mt-1 pr-5"></span>
                                </div>
                                <div className="flex-col h-full">
                                    <select {...register("shipper", { required: true, maxLength : 100 })} className="form-select  input-md bg-input w-full rounded-full input-field" id="shipper">
                                        <option value="" disabled={true} selected>Seleziona il corriere</option>
                                        <option value="GLS" >Corriere Espresso - GLS</option>
                                        <option value="STEF" >Corriere Refrigerato - STEF</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-1/2">
                                <div className="flex items-center justify-between">
                                    <label className="label font-bold pl-4">
                                        <span className="label-text">Costo</span>
                                    </label>
                                </div>
                                <input
                                    id="shippingPrice" min={1} max={9999} name="shippingPrice" type="number" defaultValue={1} step="any"
                                    className="input input-md bg-input w-full rounded-full input-field shadow-10"
                                    {...register("shippingPrice", { required: true, maxLength : 100 })}
                                />
                            </div>
                        </div>

                    </div>

                    : null}
                {/*Optional content for shipping*/}


                <div className="mt-5 flex justify-between gap-10">
                    <div className="w-1/2">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4" htmlFor="eventDate">
                                <span className="label-text">Data evento</span>
                            </label>
                                <span className="text-error title-margin label-text mt-1 pr-5">{errors.eventDate && "Campo obbligatorio"}</span>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex-col h-full">
                            <input type="date" id="eventDate" className="input-md bg-input w-full rounded-full input-field"
                                   {...register("eventDate", { required: true, maxLength : 10 })}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4">
                                <span className="label-text">{isToBeShipped ? "Data spedizione" : "Data ritiro"}</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5">{(errors.shippingDate || errors.pickUpDate) && "Campo obbligatorio"}</span>
                        </div>
                        <div className="flex-col h-full">
                            <input type="date" id={isToBeShipped ? "shippingDate" : "pickUpDate"}
                                   {...register(isToBeShipped ? "shippingDate" : "pickUpDate", { required: true, maxLength : 10 })}
                                   className="input-md bg-input w-full rounded-full input-field"/>
                        </div>
                    </div>
                </div>

                <div className="mt-5 flex justify-between gap-10">
                    <div className="w-full">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4">
                                <span className="label-text recurrence" >Ricorrenza</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex-col h-full">
                            <select {...register("recurrence", { required: false, maxLength : 100 })} className="form-select  input-md bg-input w-full rounded-full input-field">
                                <option value="" selected>Seleziona la ricorrenza</option>
                                <option value="Matrimonio" >Matrimonio</option>
                                <option value="Nozze d'oro" >Nozze d'oro</option>
                                <option value="Anniversario" >Anniversario</option>
                                <option value="Laurea" >Laurea</option>
                                <option value="Nascita" >Nascita</option>
                                <option value="Compleanno" >Compleanno</option>
                                <option value="Battesimo" >Battesimo</option>
                                <option value="Comunione" >Comunione</option>
                                <option value="Cresima" >Cresima</option>
                                <option value="18esimo" >18esimo</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="mt-5 flex justify-between gap-10">
                    <div className="w-full">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4">
                                <span className="label-text">Cliente</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5">{errors.customer && "Campo obbligatorio"}</span>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex-col h-full">
                            <select {...register("customer", { required: true, maxLength : 100 })} className="form-select  input-md bg-input w-full rounded-full input-field" onChange={handleNewCustomer}>
                                <option value="newCustomer"><i className="fa-solid fa-circle-plus fa-1xs mt-2"></i>Nuovo Cliente</option>
                                {customers?.map((customer) => {
                                    return (
                                        <option selected={customer._id === order?.customer?.toString()} value={customer._id} key={customer._id}>{customer.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="mt-5 flex justify-between gap-10">
                    <div className="w-1/2">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4">
                                <span className="label-text">Nominativo</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5">{errors.name && "Campo obbligatorio"}</span>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex-col h-full">
                            <input
                                id="name" disabled={!isNewCustomer} value={!!customerInfo ? customerInfo['name'] : null} type="text" maxLength={100}
                                className="input disabled:bg-input input-md w-full rounded-full input-field shadow-10 bg-input"
                                {...register("name", { required: (isNewCustomer), maxLength : 100 })}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4">
                                <span className="label-text">Provenienza</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5">{errors.deliveryShipping && "Campo obbligatorio"}</span>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex-col h-full">
                            <input
                                id="origin" type="text" maxLength={100} value={!!customerInfo ? customerInfo['origin'] : null} disabled={!isNewCustomer}
                                className="input disabled:bg-input input-md w-full rounded-full input-field shadow-10 bg-input"
                                {...register("origin", { required: (isNewCustomer), maxLength : 100 })}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-5 flex justify-between gap-10">
                    <div className="w-1/2">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4">
                                <span className="label-text">Email</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5">{errors.email && "Campo obbligatorio"}</span>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex-col h-full">
                            <input
                                id="email" type="email" maxLength={100} value={!!customerInfo ? customerInfo['email'] : null} disabled={!isNewCustomer}
                                className="input disabled:bg-input input-md w-full rounded-full input-field shadow-10 bg-input"
                                {...register("email", { required: (isNewCustomer), maxLength : 100 })}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4">
                                <span className="label-text">Telefono</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5">{errors.telephone && "Campo obbligatorio"}</span>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex-col h-full">
                            <input
                                id="telephone" type="text" maxLength={100} value={!!customerInfo ? customerInfo['telephone'] : null} disabled={!isNewCustomer}
                                className="input disabled:bg-input input-md w-full rounded-full input-field shadow-10 bg-input"
                                {...register("telephone", { required: (isNewCustomer), maxLength : 100 })}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-5 flex justify-between gap-10">
                    <label className="title text-gray-700 mr-3 leading-tight">
                        Prodotti
                    </label>
                </div>
                <div className="mt-2 flex">
                    <div className="bg-input rounded-3xl flex-col w-full p-10">
                        {(!toggleShowAllProducts) ? (<div>
                            {(isAddingFirstProduct) ? (
                                <div>
                                    <div className="mb-5">
                                        <label className="font-bold text-center">
                                            Non ci sono prodotti, clicca il pulsante per aggiungerli
                                        </label>
                                    </div>
                                    <div>
                                        <i onClick={handleIsAddingFirstProduct} className="cursor-pointer fa-solid fa-circle-plus fa-3x"></i>
                                    </div>
                                </div>
                            ) : (
                                <form className="flex-col flex">
                                    <div className="flex justify-between">
                                        <div className="w-4/5">
                                            <div className="flex items-center justify-between">
                                                <label className="label font-bold pl-4" htmlFor="productName">
                                                    <span className="label-text">Prodotto</span>
                                                </label>
                                            </div>
                                            <button onClick={handleOpenDialogProduct}
                                                    type="button"
                                                    className="text-primary bg-input w-full rounded-full input-field shadow-10 flex items-center justify-between"
                                            >{(!!customProductInfo) ? (<span className="font-bold text-2xl ml-5">{customProductInfo.name}</span>) : (<span><span className="font-bold text-2xl ml-5">Seleziona</span> <i className="mr-5 fa-solid fa-circle-plus"></i></span>)}</button>
                                        </div>
                                        <div className="w-1/5 ml-5">
                                            <div className="flex items-center justify-between">
                                                <label className="label font-bold pl-4" htmlFor="productName">
                                                    <span className="label-text">Quantità</span>
                                                </label>
                                            </div>
                                            <input
                                                id="quantity" min={1} name="quantity" onChange={changeQuantity} type="number" maxLength={100} defaultValue={1}
                                                className="input input-md bg-input w-full rounded-full input-field shadow-10"
                                            />
                                        </div>
                                        <div className="w-1/5 ml-5">
                                            <div className="invisible">
                                                <label className="label">
                                                </label>
                                            </div>
                                            <div className="flex h-full items-center justify-around">
                                                <span className="mr-3 text-xl font-bold text-primary">Pz</span>
                                                <label htmlFor="default-toggle"
                                                       className="inline-flex relative items-center cursor-pointer">
                                                    <input type="checkbox" onChange={changeTypePrice} id="default-toggle" className="sr-only peer" />
                                                    <div className="bg-secondary w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-secondary rounded-full peer   peer-checked:after:translate-x-full peer-checked:after:bg-primary after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-primary after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-secondary"/>
                                                </label>
                                                <span className="ml-3 text-xl font-bold text-primary">Kg</span>
                                            </div>
                                        </div>
                                    </div>
                                    {(!!customProductInfo) ? (
                                        <div className="flex ml-5 mt-5 justify-between">
                                            {((customProductInfo.kgPrice && typePrice === 'kgPrice') || (customProductInfo.pzPrice && typePrice === 'pzPrice')) ?
                                                <div className="flex">
                                                    <div className="flex flex-col ${}">
                                                        <span
                                                            className="text-gray-600 font-semibold text-l">a €{typePrice === 'kgPrice' ? (`${customProductInfo.kgPrice ? customProductInfo.kgPrice.toFixed(2) : "nd"}/Kg`) : (`${customProductInfo.pzPrice ? customProductInfo.pzPrice.toFixed(2) : "nd"}/Pz`)}</span>
                                                        <span onClick={handleToggleDiscount}
                                                              className="text-3xl font-light text-black cursor-pointer"><u>€{isFree ? (0) : (typePrice === 'kgPrice' ? ((customProductInfo.kgPrice * productQuantity) - discount).toFixed(2) : ((customProductInfo.pzPrice * productQuantity) - discount).toFixed(2))}</u><i
                                                            className="fa-solid fa-pencil"></i></span>
                                                    </div>
                                                    <div className="m-5">
                                                        <label
                                                            className="cursor-pointer flex items-center justify-between">
                                                            {toggleDiscount ? (<div
                                                                className="label-text justify-self-start text-2xl text-black">Sconto:
                                                                €<input onChange={handleDiscount} type="number"
                                                                        max={(typePrice === 'kgPrice' ? ((customProductInfo.kgPrice * productQuantity)).toFixed(2) : ((customProductInfo.pzPrice * productQuantity)))}
                                                                        placeholder="0" min={0}
                                                                        className="bg-input checkbox-lg mr-2 w-24"/><i
                                                                    onClick={handleToggleDiscount}
                                                                    className="fa-solid fa-circle-xmark"></i></div>) : (
                                                                <span
                                                                    className="label-text justify-self-start text-2xl text-black"><input
                                                                    onClick={handleIsFree} type="checkbox"
                                                                    className="checkbox bg-secondary checkbox-lg mr-2"/> Omaggio</span>)}
                                                        </label>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    Al prodotto mancano dei valori
                                                </div>}
                                            <button type="button" onClick={(addToTheList)} className="m-5 flex justify-end btn rounded-full btn-primary">
                                                + Aggiungi
                                            </button>
                                        </div>
                                    ) : (<span/>)}
                                </form>
                            )}
                        </div>) : (<div className="flex flex-col justify-between">
                            {orderProducts.map((orderProduct, index) => {
                                return(
                                    <div className="flex align-middle p-3 justify-between shadow rounded-full w-full m-2">
                                       <div className="flex items-center justify-center">
                                           <span className=" text-2xl font-bold">{orderProduct.name}</span>
                                           <span className="text-2xl ml-5">{orderProduct.quantity}</span>
                                           <span className="ml-1 text-2xl"> {orderProduct.typeQuantity === 'kgPrice' ? ('Kg') : ('Pz')}</span>
                                       </div>
                                       <div className="flex items-center justify-center">
                                           <span className="text-3xl mr-2 font-bold">€{orderProduct.isFree ? 0 : orderProduct.typeQuantity === 'kgPrice' ? ((orderProduct.quantity * orderProduct.kgPrice) - orderProduct.discount).toFixed(2) : ((orderProduct.quantity * orderProduct.pzPrice) - orderProduct.discount).toFixed(2) }</span>
                                           <button onClick={() => handleRemoveFromList(index)} type="button" className="btn-primary rounded-full w-10 h-10"><i className="fa-solid fa-minus"></i></button>
                                       </div>
                                    </div>
                                )

                            })}
                            <div className="flex justify-center mt-5">
                                <i onClick={() => setToggleShowAllProducts(false)} className="cursor-pointer fa-solid fa-circle-plus fa-3x"></i>
                            </div>
                            <div className="flex flex-col items-end mt-5">
                                <span className="text-2xl">Totale</span>
                                <span className="text-4xl font-bold">€{orderProducts.reduce(((a,b) => a + b.totalPrice),0).toFixed(2)}</span>
                            </div>
                        </div>)}
                    </div>
                </div>

                <div className="mt-5 flex justify-between gap-10">
                    <label className="title text-gray-700 mr-3 leading-tight">
                        Extra
                    </label>
                </div>

                <div className="mt-5 flex justify-between gap-10">
                    <div className="w-full">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4">
                                <span className="label-text">Note</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex-col h-full">
                            <textarea
                                {...register("extraNotes", { required: false, maxLength : 300 })}
                                id="extraNotes"
                                className="bg-input w-full rounded-full input-field p-5" placeholder="Note extra"/>
                        </div>
                    </div>
                </div>

                <div className="mt-5 flex justify-between mb-32">
                    <div className="w-full">
                        <div className="flex items-center justify-between">
                            <label className="label font-bold pl-4">
                                <span className="label-text">Allegati</span>
                            </label>
                            <span className="text-error title-margin label-text mt-1 pr-5"></span>
                        </div>
                        <div className="flex px-3 h-full bg-input rounded-full">
                            <div className="flex w-full justify-start items-center">
                                <label className="bg-secondary m-2 rounded-full  cursor-pointer p-8 space-y-5" htmlFor="attachments">
                                    <div>
                                        <i className="fa-solid fa-paperclip fa-2xl"></i>
                                        <i className="fa-solid fa-circle-plus fa-1xs mt-2"></i>
                                    </div>
                                    <div className="text">
                                        Aggiungi
                                        <input {...register("attachments", { required: false })} onChange={handleInputImage} id="attachments" type="file" style={{display : "none"}} accept=".png, .jpg, .jpeg capture=camera"/>
                                    </div>
                                </label>
                                {imagePreview.map((image : Blob | MediaSource, index) => {
                                    return (
                                        <div className="bg-secondary m-2 rounded-full relative">
                                            <img style={{objectFit : "contain", display : "inline-block", width : "100px", height : "100px", borderRadius : "100%", backgroundPosition : "50% 50%"}} src={URL.createObjectURL(image)} />
                                            <button onClick={!!orderId ? () => handleOpenDialogDeleteImage(index) : () => handleRemoveImageFromList(index)} style={{right : "10px", top : "10px", position : "absolute"}} className="w-7 h-7 rounded-full btn-primary" type="button"><i  className="fa-solid fa-trash-can"></i></button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <Dialog
                open={openDialogDeleteImage}
                onClose={handleCloseDialogDeleteImage}
                style={{
                    borderRadius: 2
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <span className="text-xl font-bold">Sicuro di voler eliminare?</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà l'allegato dalla lista.
                        Questa azione sarà irreversibile.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDialogDeleteImage} className="flex items-center justify-around btn rounded-full btn-secondary">
                        Annulla
                    </button>
                    <button onClick={() => handleRemoveImageFromList(removeImageId)} className="flex items-center justify-around btn rounded-full btn-warning border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogProduct}
                onClose={handleClosaDialogProduct}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "100vh",
                        },
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" className="flex flex-col justify-between">
                    <div className="flex">
                        <div>
                            <i onClick={handleClosaDialogProduct} className="left-0 cursor-pointer fa-solid fa-xmark fa-3x"></i>
                        </div>
                        <div className="flex flex-row w-full justify-center mt-10">
                            <input onChange={handleSearchBarProducts} placeholder="Cerca per nome o per codice" className="w-2/3 input-md bg-secondary rounded-full input-field"/>
                        </div>
                    </div>

                    <div className="m-5 px-10 rounded-full flex justify-between items-center ">
                        <div className=" flex justify-between items-center gap-40 ">
                            <div className="flex-col items-center w-10">
                                <span className="text-lg font-bold">NOME</span>
                            </div>
                            <div className="flex-col items-center">
                                <span className="text-lg font-bold">CODICE</span>
                            </div>
                        </div>
                        <div className=" flex justify-between items-center gap-20">
                            <div className="flex-col  items-center mr-5">
                                <span className="text-lg font-bold">€/Kg</span>
                            </div>
                            <div className="flex-col  mr-5 items-center">
                                <span className="text-lg font-bold">€/Pz</span>
                            </div>
                        </div>
                    </div>

                </DialogTitle>
                <DialogContent className="text-center">
                    <InfiniteScroll
                        next={getNextProducts}
                        hasMore={hasMore}
                        loader={
                            <button onClick={getNextProducts} className="mt-5 btn btn-ghost">Carica altri prodotti</button>
                        }
                        dataLength={1000}
                        scrollableTarget="scrollableDiv"
                        className="rounded-xl"
                    >
                        <div className="pb-10 h-scroll-2 rounded-xl" id="scrollableDiv">
                            {filteredProcuts?.map((product) => {
                                return (
                                    <div className="m-5 px-10 py-5 rounded-full flex justify-between items-center bg-input cursor-pointer" onClick={() => customProduct(product)}>
                                        <div className=" flex justify-between items-center gap-40">
                                            <div className="flex-col items-center w-10">
                                                <span className="text-lg">{product.name}</span>
                                            </div>
                                            <div className="flex-col items-center">
                                                <span className="text-lg">{product.code}</span>
                                            </div>
                                        </div>
                                        <div className=" flex justify-between items-center space-x-3">
                                            <div className="flex-col items-center mr-5">
                                                    <span className="text-lg">€{product.kgPrice ? product.kgPrice.toFixed(2) : "nd"}/Kg</span>{/*<span className="text-2xl">.44</span>*/}
                                            </div>
                                            <div className="flex-col mr-5 items-center">
                                                    <span className="text-lg">€{product.pzPrice ? product.pzPrice.toFixed(2) : "nd"}/Pz</span>{/*<span className="text-2xl">.44</span>*/}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </InfiniteScroll>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDialogDeleteOrder}
                onClose={handleCloseDialogDeleteOrder}
                style={{
                    borderRadius: 2
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <span className="text-xl font-bold">Sicuro di voler eliminare?</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà l'ordine dalla lista.
                        Questa azione sarà irreversibile.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDialogDeleteOrder} className="flex items-center justify-around btn rounded-full btn-secondary">
                        Annulla
                    </button>
                    <button onClick={handleDeleteOrder} className="flex items-center justify-around btn rounded-full btn-warning border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
