import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const notify = (message: string, textColor: string) => {
    toast(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        style : {borderRadius: "1.5em", backgroundColor: '#FEF1EE', color: textColor},
    });
}