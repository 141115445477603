import React from 'react';
import '@assets/style/App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import PrivateRoute from "@utils/PrivateRoute";
import {Customers, Dashboard, Login, ManageCustomer, Orders, Products, ManageOrder, ManageProduct} from "@pages";
import { ToastContainer } from 'react-toastify';

function App() {

    return (
    <div className="App">

        <ToastContainer
            position="top-center"
            autoClose={3000}
        />

      <Routes>
          <Route path='*' element={<Navigate to={'/auth/login'}/>}/>
          <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard/>}/>
              <Route path="/customers" element={<Customers/>}/>
              <Route path="/customers/manage" element={<ManageCustomer/>}/>
              <Route path="/orders" element={<Orders/>}/>
              <Route path="/orders/manage" element={<ManageOrder/>}/>
              <Route path="/products" element={<Products/>}/>
              <Route path="/products/manage" element={<ManageProduct/>}/>
          </Route>
          <Route path='/auth/login' element={<Login/>} />
      </Routes>
    </div>
  );
}

export default App;
